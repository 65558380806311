import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, computed, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { AuthorizationService } from '@app/security/authorization.service';
import { AnalyticsService } from '@app/analytics/analytics.service';
import { NavigationService } from '@app/portal/navigation/navigation.service';
import AnalyticsPortalKeys from '@app/portal/portal-analytics';
import { KeycloakService } from '@app/security/keycloak/keycloak.service';
import { patchState, signalState } from '@ngrx/signals';
import { formatInTimeZone } from 'date-fns-tz';
import workspaceApp from '@app/workspaces/WorkspaceEnrichedAppRoute';

export type DashboardState = {
    showAnimatedBackground: boolean;
};

@Component({
    selector: 'sphere-dashboard-v2',
    templateUrl: './dashboard-v2.component.html',
    styleUrls: ['./dashboard-v2.component.scss'],
    animations: [
        trigger('slideInCards', [
            transition(':enter', [
                query(
                    '.plugin-card',
                    [
                        style({ transform: 'translateY(100%)', opacity: 0 }),
                        stagger('35ms', [animate('180ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ transform: 'translateY(0%)', opacity: 1 }))])
                    ],
                    { optional: true }
                )
            ])
        ])
    ],
    standalone: false
})
export class DashboardV2Component implements OnInit, OnDestroy {
    private readonly authorization = inject(AuthorizationService);

    private keycloak = inject(KeycloakService);
    private nav = inject(NavigationService);
    private sanitizer = inject(DomSanitizer);

    readonly now = signal<Date>(new Date());
    private intervalID: any;

    readonly state = signalState<DashboardState>({
        showAnimatedBackground: false
    });

    readonly firstName = computed(() => {
        return this.keycloak.userFullName;
    });

    public readonly apps = this.authorization.getAccessibleSphereApps([workspaceApp, ...this.nav.apps]);

    constructor(analytics: AnalyticsService) {
        analytics.page(AnalyticsPortalKeys.category, AnalyticsPortalKeys.pages.homeDashboard);
    }

    ngOnInit() {
        this.intervalID = setInterval(() => {
            this.now.set(new Date());
        }, 1000);
    }

    ngOnDestroy() {
        clearInterval(this.intervalID);
    }

    setShowAnimatedBackground() {
        patchState(this.state, state => ({
            showAnimatedBackground: !state.showAnimatedBackground
        }));
    }

    getLogo(logoUri: string): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(`url("${logoUri}")`);
    }

    formatInTimeZone(timeZone: string) {
        return computed(() => {
            return {
                time: formatInTimeZone(this.now(), timeZone, 'h:mm'),
                seconds: formatInTimeZone(this.now(), timeZone, ':ss'),
                ampm: formatInTimeZone(this.now(), timeZone, 'a'),
                date: formatInTimeZone(this.now(), timeZone, 'MMMM, do')
            };
        });
    }
}
