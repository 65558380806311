import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { Component, computed, inject, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { AuthorizationService } from '@app/security/authorization.service';
import { AnalyticsService } from '@app/analytics/analytics.service';
import { NavigationService } from '@app/portal/navigation/navigation.service';
import AnalyticsPortalKeys from '@app/portal/portal-analytics';
import workspaceApp from '@app/workspaces/WorkspaceEnrichedAppRoute';
import { patchState, signalState } from '@ngrx/signals';

export type DashboardState = {
    showAnimatedBackground: boolean;
    hoverApp: Route;
};

@Component({
    selector: 'sphere-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: [
        trigger('slideInCards', [
            transition(':enter', [
                query(
                    '.plugin-card',
                    [
                        style({ transform: 'translateY(100%)', opacity: 0 }),
                        stagger('35ms', [animate('180ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ transform: 'translateY(0%)', opacity: 1 }))])
                    ],
                    { optional: true }
                )
            ])
        ]),
        trigger('cardHover', [
            state('*', style({ opacity: 0.4, background: `linear-gradient(0deg, {{start}} 60%, {{end}} 100%)` }), {
                params: { start: `var(--background-color-darker)`, end: 'var(--background-color-darker)' }
            }),
            state('transparent', style({ opacity: 0 })),
            transition('* <=> *', animate('300ms ease-in-out'))
        ])
    ],
    standalone: false
})
export class DashboardComponent implements OnInit {
    private readonly authorization = inject(AuthorizationService);

    public readonly state = signalState<DashboardState>({
        showAnimatedBackground: false,
        hoverApp: null
    });
    readonly hoverAppColorRaw = computed(() => {
        if (!this.state.hoverApp()) {
            return 'transparent';
        }
        return this.state.hoverApp().data.cardBackground;
    });

    readonly hoverAppColorRange = computed(() => {
        if (!this.hoverAppColorRaw() || this.hoverAppColorRaw()[0] !== '#') {
            return {
                start: 'var(--background-color-darker)',
                end: this.hoverAppColorRaw()
            };
        }
        let colorBase: string = this.hoverAppColorRaw();
        if (this.hoverAppColorRaw().length === 9) {
            colorBase = colorBase.slice(0, -2);
        }
        return {
            start: `var(--background-color-darker)`,
            end: `${colorBase}99`
        };
    });

    public readonly apps = this.authorization.getAccessibleSphereApps([workspaceApp, ...this.nav.apps]);

    constructor(
        private nav: NavigationService,
        private analytics: AnalyticsService
    ) {
        this.analytics.page(AnalyticsPortalKeys.category, AnalyticsPortalKeys.pages.homeDashboard);
    }

    ngOnInit() {
        patchState(this.state, {
            hoverApp: null
        });
    }

    setShowAnimatedBackground() {
        patchState(this.state, state => ({
            showAnimatedBackground: !state.showAnimatedBackground
        }));
    }

    setHoverApp(app: Route | null) {
        patchState(this.state, {
            hoverApp: app
        });
    }
}
