import { AppRoute, AppRouteData } from '@app/app-routing.module';
import { RoleGuard } from '@app/security/role.guard';

const WorkspaceEnrichedAppRoute: AppRoute = {
    path: 'workspaces',
    canActivate: [RoleGuard],
    data: <AppRouteData>{
        alpha: true,
        title: 'Workspaces',
        name: 'Workspaces',
        accessRole: 'drona://plugin/workspaces',
        tagLine: 'Customize your data viewing experience',
        description: '<p>Customize your data viewing experience</p>',
        cardBackground: 'rgba(36,50,38,0.5)',
        logoSvg:
            'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNy41IiBoZWlnaHQ9IjI3LjUiIHZpZXdCb3g9IjAgMCAyNy41IDI3LjUiPg0KICA8ZyBpZD0iR3JvdXBfNDMiIGRhdGEtbmFtZT0iR3JvdXAgNDMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDguOCAtNDYxLjgpIj4NCiAgICA8Y2lyY2xlIGlkPSJPdmFsLUNvcHktMyIgY3g9IjEzLjA1IiBjeT0iMTMuMDUiIHI9IjEzLjA1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0NDkuNSA0NjIuNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzI3OTU2MiIgc3Ryb2tlLXdpZHRoPSIxLjQiLz4NCiAgICA8ZyBpZD0iR3JvdXBfNDEiIGRhdGEtbmFtZT0iR3JvdXAgNDEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQ1NSA0NjgpIj4NCiAgICAgIDxnIGlkPSJHcm91cF80MiIgZGF0YS1uYW1lPSJHcm91cCA0MiI+DQogICAgICAgIDxwYXRoIGlkPSJQYXRoXzYiIGRhdGEtbmFtZT0iUGF0aCA2IiBkPSJNNS4xMDcsMTAuNzY4YTMuMTA3LDMuMTA3LDAsMSwwLDMuMTA3LDMuMTA3QTMuMTE2LDMuMTE2LDAsMCwwLDUuMTA3LDEwLjc2OFpNOS43NjgsM2EzLjEwNywzLjEwNywwLDEsMCwzLjEwNywzLjEwN0EzLjExNiwzLjExNiwwLDAsMCw5Ljc2OCwzWm00LjY2MSw3Ljc2OGEzLjEwNywzLjEwNywwLDEsMCwzLjEwNywzLjEwN0EzLjExNiwzLjExNiwwLDAsMCwxNC40MjksMTAuNzY4WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIgLTMpIiBmaWxsPSIjMjc5NTYyIi8+DQogICAgICA8L2c+DQogICAgPC9nPg0KICA8L2c+DQo8L3N2Zz4NCg==',
        logoSvgActive:
            'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNy4xIiBoZWlnaHQ9IjI3LjEwMSIgdmlld0JveD0iMCAwIDI3LjEgMjcuMTAxIj4NCiAgPHBhdGggaWQ9IlN1YnRyYWN0aW9uXzEwIiBkYXRhLW5hbWU9IlN1YnRyYWN0aW9uIDEwIiBkPSJNMTMuNTUsMTM0Mi4xYTEzLjU1LDEzLjU1LDAsMSwxLDkuNTgxLTMuOTY5QTEzLjQ2MiwxMy40NjIsMCwwLDEsMTMuNTUsMTM0Mi4xWm00Ljg3OS0xMy43NzRhMy4xMDcsMy4xMDcsMCwxLDAsMy4xMDgsMy4xMDhBMy4xMTEsMy4xMTEsMCwwLDAsMTguNDI5LDEzMjguMzI3Wm0tOS4zMjEsMGEzLjEwNywzLjEwNywwLDEsMCwzLjEwNywzLjEwOEEzLjExMSwzLjExMSwwLDAsMCw5LjEwOCwxMzI4LjMyN1ptNC42Ni03Ljc2OGEzLjEwNywzLjEwNywwLDEsMCwzLjEwOCwzLjEwOEEzLjExMSwzLjExMSwwLDAsMCwxMy43NjgsMTMyMC41NTlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0xMzE1KSIgZmlsbD0iIzI3OTU2MiIvPg0KPC9zdmc+DQo='
    }
};

export default WorkspaceEnrichedAppRoute;
