@if (state.showAnimatedBackground()) {
    <video class="video-bg" autoplay muted loop>
        <source src="../../assets/video/cityscape_1080.mp4" type="video/mp4" />
    </video>
} @else {
    <div class="image-bg">
        <img alt="cityscape" ngSrc="../../assets/images/cityscape.jpg" fill />
    </div>
}

<div class="SphereHomeDashboard sphere-density__comfortable">
    <mat-card class="SphereAppsCard" appearance="outlined">
        <mat-card-content>
            <mat-nav-list>
                <a mat-list-item class="SphereApp" [routerLink]="['/', 'workspaces']">
                    <div matListItemIcon>
                        <mat-icon>workspaces</mat-icon>
                    </div>
                    <span matListItemTitle> Workspaces </span>
                </a>
            </mat-nav-list>
            @if (apps()?.length) {
                <mat-nav-list>
                    @for (app of apps(); track $index) {
                        <a mat-list-item class="SphereApp" [routerLink]="['/', app.path]">
                            <div matListItemIcon style="background-size: cover" [style.background-image]="getLogo(app.data['logoSvg'])"></div>
                            <span matListItemTitle>
                                {{ app.data?.name }}
                            </span>
                        </a>
                    }
                </mat-nav-list>
            } @else {
                <p class="NoAppsAvailable">We're sorry, you are not authorized to use any application.</p>
            }
        </mat-card-content>
    </mat-card>
    <div class="SphereSplashScreen">
        <div class="SplashScreenWidgetRow SplashScreenWorldClocks">
            <mat-card class="SplashScreenCard">
                <mat-card-content>
                    @let nyTime = formatInTimeZone('America/New_York');
                    <h3 class="ClockCity">New York</h3>
                    <h2 class="ClockTime">
                        {{ nyTime().time }}<span class="ClockTimeSeconds">{{ nyTime().seconds }}</span
                        ><span class="ClockTimeAMPM">{{ nyTime().ampm }}</span>
                    </h2>
                    <h3 class="ClockDate">{{ nyTime().date }}</h3>
                </mat-card-content>
            </mat-card>

            <mat-card class="SplashScreenCard">
                <mat-card-content>
                    @let londonTime = formatInTimeZone('Europe/London');
                    <h3 class="ClockCity">London</h3>
                    <h2 class="ClockTime">
                        {{ londonTime().time }}<span class="ClockTimeSeconds">{{ londonTime().seconds }}</span
                        ><span class="ClockTimeAMPM">{{ londonTime().ampm }}</span>
                    </h2>
                    <h3 class="ClockDate">{{ londonTime().date }}</h3>
                </mat-card-content>
            </mat-card>

            <mat-card class="SplashScreenCard">
                <mat-card-content>
                    @let tokyoTime = formatInTimeZone('Asia/Tokyo');
                    <h3 class="ClockCity">Tokyo</h3>
                    <h2 class="ClockTime">
                        {{ tokyoTime().time }}<span class="ClockTimeSeconds">{{ tokyoTime().seconds }}</span
                        ><span class="ClockTimeAMPM">{{ tokyoTime().ampm }}</span>
                    </h2>
                    <h3 class="ClockDate">{{ tokyoTime().date }}</h3>
                </mat-card-content>
            </mat-card>

            <mat-card class="SplashScreenCard">
                <mat-card-content>
                    @let sydneyTime = formatInTimeZone('Australia/Sydney');
                    <h3 class="ClockCity">Sydney</h3>
                    <h2 class="ClockTime">
                        {{ sydneyTime().time }}<span class="ClockTimeSeconds">{{ sydneyTime().seconds }}</span
                        ><span class="ClockTimeAMPM">{{ sydneyTime().ampm }}</span>
                    </h2>
                    <h3 class="ClockDate">{{ sydneyTime().date }}</h3>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="SplashScreenWidgetRow">
            <mat-card class="SplashScreenCard">
                <mat-card-header>
                    <mat-card-title>Welcome back {{ firstName() }}</mat-card-title>
                    <mat-card-subtitle>Pick up where you left off.</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <a mat-fab extended>
                        <mat-icon>insights</mat-icon>
                        Daily curve status
                    </a>
                    <a mat-fab extended>
                        <mat-icon>fmd_bad</mat-icon>
                        Critical data deliveries
                    </a>
                    <a mat-fab extended>
                        <mat-icon>pending_actions</mat-icon>
                        Workflow executions
                    </a>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<section class="dashboard-bottom-section">
    <mat-divider [style.margin-bottom.px]="20" />
    <mat-slide-toggle [checked]="state.showAnimatedBackground()" (toggleChange)="setShowAnimatedBackground()">Animated background</mat-slide-toggle>
</section>
