import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ExperimentalFeatureService } from '@app/portal/experimental-features/experimental-feature.service';
import { Observable } from 'rxjs';
import { UNAUTHORIZED } from '@app/generic-routes';

@Injectable({
    providedIn: 'root'
})
export class AlphaFeatureGuard implements CanActivate {
    constructor(
        private experimentalFeatures: ExperimentalFeatureService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.experimentalFeatures.hasAlphaFeatureAccess) {
            return true;
        }
        this.router.navigate(['/', UNAUTHORIZED.path], { replaceUrl: true });
        return false;
    }
}

@Injectable({
    providedIn: 'root'
})
export class BetaFeatureGuard implements CanActivate {
    constructor(
        private experimentalFeatures: ExperimentalFeatureService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.experimentalFeatures.hasBetaFeatureAccess) {
            return true;
        }
        this.router.navigate(['/', UNAUTHORIZED.path], { replaceUrl: true });
        return false;
    }
}
